<template>
  <v-row class="mt-4">
    <v-col
      cols="12"
      md="8"
    >
      <p class="mt-2">
        <b>{{ $t('lbl.adulto') }}</b>
        (<span>{{ $t('lbl.mayorQue') }}</span>)
      </p>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="rangeAgeContrateHotel.adults_min_years"
        :disabled="$store.state.app.onlyShow"
        :label="$t('lbl.years')"
        outlined
        dense
        hide-details
        type="number"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <p class="mt-2">
        <b>{{ $t('lbl.child') }}</b>
        (<span>{{ $t('lbl.hasta') }}</span>)
      </p>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="rangeAgeContrateHotel.childs_max_years"
        :disabled="$store.state.app.onlyShow || onlyAdult"
        :label="$t('lbl.years')"
        outlined
        dense
        hide-details
        type="number"
      ></v-text-field>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <p class="mt-2">
        <b>{{ $t('lbl.infat') }}</b>
        (<span>{{ $t('lbl.hasta') }}</span>)
      </p>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="rangeAgeContrateHotel.infant_max_years"
        :disabled="$store.state.app.onlyShow || onlyAdult"
        :label="$t('lbl.years')"
        outlined
        dense
        hide-details
        type="number"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      allRooms: [],
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
    }
  },
  computed: {
    ...mapState({
      rangeAgeContrateHotel: state => state.app.rangeAgeContrateHotel,
      onlyAdult: state => state.app.onlyAdult,
      allInclude: state => state.app.allInclude,
    }),
  },
  methods: {
    ...mapMutations(['deleteRoomSeasonContrateHotel']),
  },
}
</script>

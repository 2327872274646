<template>
  <fragment>
    <v-col
      cols="12"
      md="3"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="room.name"
            class="my-input"
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="1"
    ></v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-row>
        <v-col
          v-for="(price, indC) in room.prices"
          :key="indC"
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="price.price"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.price')"
            class="my-input"
            outlined
            dense
            hide-details
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      allRooms: [],
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
    }
  },
  computed: {
    ...mapState({
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
    }),
  },
  methods: {
    ...mapMutations(['deleteRoomSeasonContrateHotel']),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>

<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.contratos')} / ${$t('menu.hotels')} / ${$t('btn.edit')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="backHotels()"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="model.proveedor_id"
              cols="12"
              md="4"
            >
              <p>
                <strong>{{ $t('lbl.proveedor') }}</strong>: {{ model.proveedor.name_comercial }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <p>
                <strong>{{ $t('lbl.identificador') }}</strong>: {{ model.identificador }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <p>
                <strong>{{ $t('lbl.hotel') }}</strong>: {{ hotel.name }}
              </p>
            </v-col>

            <!--<v-col
              cols="12"
              md="6"
            >
              <v-switch
                v-model="stopSale"
                :label="$t('lbl.stopSale')"
                hide-details
                class="mt-0 pt-0"
                :disabled="$store.state.app.onlyShow"
              ></v-switch>
            </v-col>-->
          </v-row>

          <!--SEASONS-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.temporadas') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowSeasons && seasonsContrateHotel.length < 6 && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addSeasonContrateHotel(idH)"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemSeasons()"
                  >
                    <v-icon>
                      {{ showRowSeasons ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowSeasons">
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <strong style="opacity:0;">.</strong>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        :label="$t('lbl.fromSeason')"
                        class="my-input"
                        hide-details
                        disabled
                      ></v-text-field>

                      <v-text-field
                        :label="$t('lbl.toSeason')"
                        class="my-input"
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!--<v-card class="mx-auto">
                    <v-card-text>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <strong style="opacity:0;">.</strong>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          {{ $t('lbl.fromSeason') }}
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          {{ $t('lbl.toSeason') }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>-->
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                ></v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-row>
                    <SeasonHotel
                      v-for="(season, index) in seasonsContrateHotel"
                      :key="index"
                      :pos="index"
                      :season="season"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--CUPOS-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.rooms') }} {{ $t('lbl.and') }} {{ $t('lbl.cupo') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowCupos && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        :disabled="!showAddCupos"
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addRoomSeasonContrateHotel(planHotel)"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemCupos()"
                  >
                    <v-icon>
                      {{ showRowCupos ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowCupos">
                <SeasonNamesHotel />
                <SeasonCuposHotel
                  v-for="(room, index) in roomsSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :room="room"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--RELEASE Y ESTANCIA MIN-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.releaseEstancia') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                ></v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemReleaseEstancia()"
                  >
                    <v-icon>
                      {{ showRowReleaseEstancia ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowReleaseEstancia">
                <SeasonNamesHotel />
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <v-text-field
                        :label="$t('lbl.releasePeriod')"
                        class="my-input"
                        hide-details
                        disabled
                      ></v-text-field>

                      <v-text-field
                        :label="$t('lbl.estanciaMin')"
                        class="my-input"
                        hide-details
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                ></v-col>
                <v-col
                  cols="12"
                  md="8"
                >
                  <v-row>
                    <SeasonReleaseHotel
                      v-for="(season, index) in seasonsContrateHotel"
                      :key="index"
                      :pos="index"
                      :season="season"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--PRECIOS DE ESTANCIA-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.priceEstancia') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                ></v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemPricesEstancia()"
                  >
                    <v-icon>
                      {{ showRowPricesEstancia ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowPricesEstancia">
                <SeasonNamesHotel />
                <SeasonPricesHotel
                  v-for="(room, index) in roomsSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :room="room"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--SUPLEMENTOS-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('menu.suplementos') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowSuplementos && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addSuplementSeasonContrateHotel"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemSuplementos()"
                  >
                    <v-icon>
                      {{ showRowSuplementos ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowSuplementos">
                <SeasonNamesHotel />
                <SeasonSuplementsHotel
                  v-for="(suplement, index) in suplementsSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :suplement="suplement"
                  :all-suplement="allSuplement"
                  :all-rooms="allRooms"
                  :only-adult="onlyAdult"
                  :all-include="allInclude"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--REDUCCIONES-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('menu.reducciones') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowReducciones && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addReduccionSeasonContrateHotel"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemReducciones()"
                  >
                    <v-icon>
                      {{ showRowReducciones ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowReducciones">
                <SeasonNamesHotel />
                <SeasonReduccionsHotel
                  v-for="(reduccion, index) in reduccionsSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :reduccion="reduccion"
                  :all-reduccion="allReduccion"
                  :all-rooms="allRooms"
                  :edad="edadHotelOnlyAdult"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--ACOMODACION-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.ocupationMax') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                ></v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemAcomodation()"
                  >
                    <v-icon>
                      {{ showRowAcomodation ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowAcomodation">
                <SeasonAcomodationRoom
                  v-for="(room, index) in roomsSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :room="room"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--RANGO EDADES-->
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.rangeAge') }}</b><br />
                  <fragment v-if="showRowRangoAge">
                    <SeasonRangeAgeRoom />
                  </fragment>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemRangoAge()"
                  >
                    <v-icon>
                      {{ showRowRangoAge ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>

          <!--OFERTAS ESPECIALES--
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.ofertSpecial') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowOfertasSpecial && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addOfertaSpecialSeasonContrateHotel"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemOfertasSpecial()"
                  >
                    <v-icon>
                      {{ showRowOfertasSpecial ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowOfertasSpecial">
                <SeasonOfertasSpecialHotel
                  v-for="(oferta, index) in ofertasSpecialSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :oferta="oferta"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>-->

          <!--COMPRA ANTICIPADA--
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="mt-2"
                >
                  <b>{{ $t('lbl.saleAnticip') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-tooltip
                    v-if="showRowOfertas && !$store.state.app.onlyShow"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-1 mx-2"
                        fab
                        dark
                        x-small
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="addOfertaSeasonContrateHotel"
                      >
                        <v-icon small>
                          {{ icons.mdiPlus }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('btn.insert') }}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemOfertas()"
                  >
                    <v-icon>
                      {{ showRowOfertas ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowOfertas">
                <SeasonOfertasHotel
                  v-for="(oferta, index) in ofertasSeasonContrateHotel"
                  :key="index"
                  :pos="index"
                  :oferta="oferta"
                  :all-suplement="allSuplement"
                  :all-reductions="allReduccion"
                  :all-rooms="allRooms"
                  :only-adult="onlyAdult"
                  :all-include="allInclude"
                />
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-2"></v-divider>-->

          <v-row v-if="contacts.length > 0">
            <v-col
              cols="12"
              md="12"
            >
              <v-row class="mt-0">
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('lbl.contacts') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemContacts()"
                  >
                    <v-icon>
                      {{ showRowContacts ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="showRowContacts">
                <fragment v-if="contactFree.length > 0">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="contactAdd"
                      :disabled="$store.state.app.onlyShow"
                      :items="contactFree"
                      :label="$t('lbl.contact')"
                      outlined
                      dense
                      hide-details
                      item-text="name"
                      item-value="id"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            {{ $t('lbl.contact') }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-tooltip
                      v-if="!$store.state.app.onlyShow"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mt-1 mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                          @click="addNewContact()"
                        >
                          <v-icon small>
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>
                </fragment>
                <fragment v-if="contacts_proveedor.length > 0">
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-uppercase">
                              {{ $t('lbl.name') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('menu.roles') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('lbl.email') }}
                            </th>
                            <th class="text-uppercase">
                              {{ $t('lbl.telefono') }}
                            </th>
                            <th
                              width="150px"
                              class="text-uppercase"
                            >
                              {{ $t('lbl.actions') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <fragment
                            v-for="(contact, indP) in contactContrateHotel"
                            :key="indP"
                          >
                            <tr>
                              <td>{{ contact.name }}</td>
                              <td>
                                <span v-if="contact.role_id">
                                  <span v-if="rols.filter(e => e.id === contact.role_id).length > 0">
                                    {{ rols.filter(e => e.id === contact.role_id)[0].name }}
                                  </span>
                                </span>
                              </td>
                              <td>
                                <span
                                  v-for="(email, indEm) in contact.emails"
                                  :key="indEm"
                                >
                                  {{ email.email }}<br />
                                </span>
                              </td>
                              <td>
                                <span
                                  v-for="(telefono, indEm) in contact.telefonos"
                                  :key="indEm"
                                >
                                  {{ telefono.telefono }}
                                  <span v-if="telefono.ext">Ext.: {{ telefono.ext }}</span>
                                  <br />
                                </span>
                              </td>
                              <td>
                                <v-tooltip
                                  v-if="!$store.state.app.onlyShow"
                                  top
                                  color="error"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="deleteContact(contact.id)"
                                    >
                                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{ $t('btn.delete') }}</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </fragment>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </fragment>
                <!--<fragment
                  v-for="(contact, i) in contacts"
                  :key="i"
                >
                  <v-col
                    v-if="contact.products.includes(typeProductHotel)"
                    cols="12"
                    md="3"
                  >
                    <v-checkbox
                      v-model="contacts_proveedor"
                      :label="contact.name"
                      :value="contact.id"
                      hide-details
                      class="mt-0 mb-2"
                    ></v-checkbox>
                  </v-col>
                </fragment>-->
              </v-row>
            </v-col>
          </v-row>
          <v-divider
            v-if="contacts.length > 0"
            class="mb-2 mt-2"
          ></v-divider>

          <v-row v-if="!noTieneCategCondicionesGenerales">
            <v-col
              cols="12"
              md="12"
            >
              <v-row class="mt-0">
                <v-col
                  cols="12"
                  md="3"
                >
                  <b>{{ $t('menu.conditionGenerals') }}</b>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    icon
                    @click="showItemConditions()"
                  >
                    <v-icon>
                      {{ showRowConditions ? icons.mdiMenuDown : icons.mdiMenuRight }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-expansion-panels v-if="showRowConditions">
            <fragment v-if="categCondicionesGenerales.length > 0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                  class="mb-4"
                >
                  <v-text-field
                    v-model="filtro"
                    :label="$t('lbl.reference')"
                    :disabled="$store.state.app.onlyShow"
                    outlined
                    dense
                    hide-details
                    @input="filtrarCateCondicionesGenerales()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-expansion-panel
                v-for="(cate, ind) in categCondicionesGenerales"
                :key="ind"
              >
                <v-expansion-panel-header v-if="cate.condicionesGenerales.length > 0">
                  {{ cate.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <fragment
                    v-for="(cond, indCond) in cate.condicionesGenerales"
                    :key="indCond"
                  >
                    <fragment v-if="cond.show">
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          md="2"
                        >
                          <v-checkbox
                            v-model="condicionesGenerales_id"
                            :disabled="$store.state.app.onlyShow"
                            :label="`${cond.name ? `${cond.name}` : ''}`"
                            :value="cond.id"
                            hide-details
                            class="pt-0"
                          ></v-checkbox>
                        </v-col>
                        <v-col
                          cols="12"
                          md="1"
                        >
                          <v-btn
                            icon
                            @click="showItem(indCond)"
                          >
                            <v-icon>
                              {{ showRow && indCond === indexRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col
                          cols="12"
                          md="9"
                        >
                          <div
                            class="col-12"
                            :class="showRow && indCond === indexRow ? '' : 'text-truncate'"
                          >
                            <p v-html="cond.description"></p>
                          </div>
                        </v-col>
                      </v-row>
                    </fragment>
                  </fragment>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </fragment>
          </v-expansion-panels>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import Vue2Filters from 'vue2-filters'
import SeasonHotel from '../utils/SeasonHotel.vue'
import SeasonNamesHotel from '../utils/SeasonNamesHotel.vue'
import SeasonCuposHotel from '../utils/SeasonCuposHotel.vue'
import SeasonReleaseHotel from '../utils/SeasonReleaseHotel.vue'
import SeasonPricesHotel from '../utils/SeasonPricesHotel.vue'
import SeasonSuplementsHotel from '../utils/SeasonSuplementsHotel.vue'
import SeasonReduccionsHotel from '../utils/SeasonReduccionsHotel.vue'
import SeasonAcomodationRoom from '../utils/SeasonAcomodationRoom.vue'
import SeasonRangeAgeRoom from '../utils/SeasonRangeAgeRoom.vue'

// import SeasonOfertasHotel from '../utils/SeasonOfertasHotel.vue'
// import SeasonOfertasSpecialHotel from '../utils/SeasonOfertasSpecialHotel.vue'

export default {
  components: {
    AppCardCode,
    SeasonHotel,
    SeasonNamesHotel,
    SeasonCuposHotel,
    SeasonReleaseHotel,
    SeasonPricesHotel,
    SeasonSuplementsHotel,
    SeasonReduccionsHotel,
    SeasonAcomodationRoom,
    SeasonRangeAgeRoom,

    // SeasonOfertasHotel,
    // SeasonOfertasSpecialHotel,
  },
  mixins: [Vue2Filters.mixin],
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      loadingSeason: true,
      pagination: {
        current: 1,
        total: 0,
      },
      model: {},
      hotel: {},
      onlyAdult: false,
      allInclude: false,
      planHotel: null,
      cantRooms: 0,
      itemsPerPage: 10,
      contacts: [],
      products: [],
      contacts_proveedor: [],
      hotelsNomAll: [],
      allSuplement: [],
      allRooms: [],
      allReduccion: [],
      permisos: sessionStorage.getItem('permisos_auth'),
      idH: sessionStorage.getItem('hotels-id'),
      showRowContacts: false,
      showRowConditions: false,
      showRowSeasons: false,
      showRowCupos: false,
      showRowReleaseEstancia: false,
      showRowPricesEstancia: false,
      showRowSuplementos: false,
      showRowReducciones: false,
      showRowAcomodation: false,
      showRowRangoAge: false,
      showRowOfertas: false,
      showRowOfertasSpecial: false,
      typeProductHotel: 0,
      planes: [],
      rols: [],
      contactAdd: null,
      categCondicionesGenerales: [],
      condicionesGenerales: [],
      noTieneCategCondicionesGenerales: true,
      condicionesGenerales_id: [],
      showCategories: false,
      showRow: false,
      indexRow: -1,
      filtro: null,
      stopSale: false,
      datesStopSale: [],
      edadHotelOnlyAdult: 0,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-contrate-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      reduccionsSeasonContrateHotel: state => state.app.reduccionsSeasonContrateHotel,
      rangeAgeContrateHotel: state => state.app.rangeAgeContrateHotel,
      ofertasSeasonContrateHotel: state => state.app.ofertasSeasonContrateHotel,
      ofertasSpecialSeasonContrateHotel: state => state.app.ofertasSpecialSeasonContrateHotel,
    }),
    showAddCupos() {
      if (this.roomsSeasonContrateHotel.length < this.cantRooms) {
        return true
      }

      return false
    },
    contactContrateHotel() {
      const arr = []

      this.contacts.forEach(contac => {
        if (this.contacts_proveedor.includes(contac.id)) {
          arr.push(contac)
        }
      })

      return arr
    },
    contactFree() {
      const arr = []
      this.contacts.forEach(contac => {
        if (contac.products.includes(this.typeProductHotel)) {
          if (!this.contacts_proveedor.includes(contac.id)) {
            arr.push(contac)
          }
        }
      })

      return this.orderBy(arr, 'name')
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    // this.menuIsVerticalNavMini = true
    this.getProducts()
  },
  created() {
    this.getRols()
    this.getItem()
    if (sessionStorage.getItem('hotel-contrato-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
    this.getSuplements()
    this.getRooms()
    this.getReduccions()
  },

  methods: {
    ...mapMutations([
      'setOnlyShow',
      'addSeasonContrateHotel',
      'addRoomSeasonContrateHotel',
      'addSuplementSeasonContrateHotel',
      'addReduccionSeasonContrateHotel',
      'addOfertaSeasonContrateHotel',
      'addOfertaSpecialSeasonContrateHotel',
      'updateSeasonContrateHotel',
      'updateRoomSeasonContrateHotel',
      'updateSuplementSeasonContrateHotel',
      'updateReduccionSeasonContrateHotel',
      'updateRangeAgeContrateHotel',
      'updateOfertaSeasonContrateHotel',
      'updateOfertaSpecialSeasonContrateHotel',
      'updateOnlyAdult',
      'updateAllInclude',
    ]),
    getItem() {
      if (sessionStorage.getItem('hotels-contrate-id') !== null) {
        const id = sessionStorage.getItem('hotels-contrate-id')
        this.axios
          .get(`contrate_hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            if (this.model.proveedor_id !== null && this.model.proveedor_id !== 'null') {
              if (this.model.proveedor.contacts !== null && this.model.proveedor.contacts !== 'null') {
                this.contacts = this.model.proveedor.contacts
              }
            }
          })
          .finally(() => {
            this.getPlans()
            setTimeout(() => {
              this.getCondiciones()

              // this.isLoading = false
              // this.isLoadingSuplementos = false
            }, 100)
          })
      } else {
        this.$router.push({ name: 'hotels-contrate-list' })
      }
    },
    showItem(indCond) {
      if (this.indexRow === indCond) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = indCond
    },
    getCategCondiciones() {
      this.axios
        .get('nom_categories_conditions?for_hotel=1&per_page=100000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categCondicionesGenerales = []

            // this.allCategCondicionesGenerales = res.data.data
            res.data.data.forEach(element => {
              const conditions = []
              this.condicionesGenerales.forEach(cond => {
                if (cond.category_id === element.id && this.model.proveedor_id === cond.proveedor_id) {
                  conditions.push({
                    ...cond,
                    show: true,
                  })
                }
              })
              this.categCondicionesGenerales.push({
                ...element,
                filtro: null,
                condicionesGenerales: conditions,
              })

              setTimeout(() => {
                this.checkConditionsGeneral()
              }, 100)
            })
          }
        })
    },
    getCondiciones() {
      this.axios
        .get('nom_conditions_generals?for_hotel=1&per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.condicionesGenerales = res.data.data
          }
        })
        .finally(() => this.getCategCondiciones())
    },
    checkConditionsGeneral() {
      this.noTieneCategCondicionesGenerales = true
      this.categCondicionesGenerales.forEach(cate => {
        if (cate.condicionesGenerales.length > 0) {
          this.noTieneCategCondicionesGenerales = false
        }
      })
    },
    filtrarCateCondicionesGenerales() {
      if (this.filtro) {
        // eslint-disable-next-line no-plusplus
        for (let pos = 0; pos < this.categCondicionesGenerales.length; pos++) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.categCondicionesGenerales[pos].condicionesGenerales.length; index++) {
            if (this.categCondicionesGenerales[pos].condicionesGenerales[index].name) {
              if (
                this.categCondicionesGenerales[pos].condicionesGenerales[index].name.toLowerCase().includes(this.filtro)
              ) {
                this.categCondicionesGenerales[pos].condicionesGenerales[index].show = true
              } else {
                this.categCondicionesGenerales[pos].condicionesGenerales[index].show = false
              }
            } else {
              this.categCondicionesGenerales[pos].condicionesGenerales[index].show = false
            }
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let pos = 0; pos < this.categCondicionesGenerales.length; pos++) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.categCondicionesGenerales[pos].condicionesGenerales.length; index++) {
            this.categCondicionesGenerales[pos].condicionesGenerales[index].show = true
          }
        }
      }
    },

    getRols() {
      this.axios
        .get('role?per_page=10000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.rols = res.data.data
          }
        })
    },
    getPlans() {
      this.axios
        .get(`nom_regimen_alimenticio?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.planes = res.data.data
        })
        .finally(() => {
          this.getHotel()
        })
    },
    backHotels() {
      sessionStorage.removeItem('hotels-show')
      sessionStorage.removeItem('hotels-id')
      sessionStorage.removeItem('hotels-contrate-temporada_id')
      this.$router.push({ name: 'hotels-contrate-update' })
    },
    getHotel() {
      if (sessionStorage.getItem('hotels-id') !== null) {
        const id = sessionStorage.getItem('hotels-id')
        this.axios
          .get(`hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.hotel = res.data.data.data
            this.cantRooms = this.hotel.rooms_id.length
            if (this.hotel.only_adult === 1) {
              this.onlyAdult = true
              if (this.hotel.edad_min) {
                if (this.hotel.edad_min.edad) {
                  this.edadHotelOnlyAdult = this.hotel.edad_min.edad
                }
              }
              this.updateOnlyAdult(true)
            } else {
              this.updateOnlyAdult(false)
            }
            if (this.hotel.all_include === 1) {
              this.allInclude = true
              this.updateAllInclude(true)
            } else {
              this.updateAllInclude(false)
            }
            if (this.hotel.all_include) {
              this.planes.forEach(element => {
                if (element.siglas.toLowerCase() === 'ti') {
                  this.planHotel = element.id
                }
              })
            } else {
              this.planes.forEach(element => {
                if (element.siglas.toLowerCase() === 'cp') {
                  this.planHotel = element.id
                }
              })
            }
          })
          .finally(() => {
            this.getSeasons()
          })
      }
    },
    getSeasons() {
      if (sessionStorage.getItem('hotels-id') !== null) {
        const json = {
          contrate_id: sessionStorage.getItem('hotels-contrate-id'),
          hotel_id: sessionStorage.getItem('hotels-id'),
        }
        this.axios
          .post('contrate_hotels/list-season', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (!res.data.success) {
              this.updateSeasonContrateHotel([])
              this.updateRoomSeasonContrateHotel([])
              this.updateRangeAgeContrateHotel({
                adults_min_years: null,
                childs_max_years: null,
                infant_max_years: null,
              })
              this.updateSuplementSeasonContrateHotel([])
              this.updateReduccionSeasonContrateHotel([])
              this.updateOfertaSeasonContrateHotel([])
              this.updateOfertaSpecialSeasonContrateHotel([])
            } else {
              this.updateSeasonContrateHotel(res.data.data.seasons.seasons)
              this.updateRoomSeasonContrateHotel(res.data.data.seasons.rooms)
              this.updateSuplementSeasonContrateHotel(res.data.data.seasons.suplements)
              this.updateRangeAgeContrateHotel(res.data.data.seasons.range_age)

              if (res.data.data.seasons.reduccions) {
                this.updateReduccionSeasonContrateHotel(res.data.data.seasons.reduccions)
              } else {
                this.updateReduccionSeasonContrateHotel([])
              }

              if (res.data.data.seasons.ofertas) {
                this.updateOfertaSeasonContrateHotel(res.data.data.seasons.ofertas)
              } else {
                this.updateOfertaSeasonContrateHotel([])
              }

              if (res.data.data.seasons.ofertas_special) {
                this.updateOfertaSpecialSeasonContrateHotel(res.data.data.seasons.ofertas_special)
              } else {
                this.updateOfertaSpecialSeasonContrateHotel([])
              }

              if (res.data.data.contacts_proveedor.length > 0) {
                this.contacts_proveedor = res.data.data.contacts_proveedor
              }

              this.stopSale = res.data.data.stopSale
              if (res.data.data.conditions) {
                this.condicionesGenerales_id = res.data.data.conditions
              } else {
                this.condicionesGenerales_id = []
              }

              sessionStorage.setItem('hotels-contrate-temporada_id', res.data.data.temporada_id)
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          res.data.data.forEach(element => {
            if (element.slug === 'hotels') {
              this.typeProductHotel = element.id
            }
          })
        })
    },
    getSuplements() {
      this.axios
        .get(`nom_suplemento_contrate/hotel?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allSuplement = res.data.data
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allRooms = res.data.data
        })
    },
    getReduccions() {
      this.axios
        .get(`nom_reducciones_contrate/hotel?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allReduccion = res.data.data
        })
    },
    save() {
      // eslint-disable-next-line camelcase
      let temporada_id = uuidv4()
      if (sessionStorage.getItem('hotels-contrate-temporada_id') !== null) {
        // eslint-disable-next-line camelcase
        temporada_id = sessionStorage.getItem('hotels-contrate-temporada_id')
      }
      const json = {
        contrate_id: sessionStorage.getItem('hotels-contrate-id'),
        hotels: sessionStorage.getItem('hotels-id'),
        temporada_id,
        contacts_proveedor: this.contacts_proveedor,
        seasons: {
          seasons: this.seasonsContrateHotel,
          rooms: this.roomsSeasonContrateHotel,
          suplements: this.suplementsSeasonContrateHotel,
          reduccions: this.reduccionsSeasonContrateHotel,
          range_age: this.rangeAgeContrateHotel,
          ofertas: this.ofertasSeasonContrateHotel,
          ofertas_special: this.ofertasSpecialSeasonContrateHotel,
        },
        publico: !this.stopSale,
        conditions: this.condicionesGenerales_id,
      }

      let proceder = true

      this.seasonsContrateHotel.forEach(element => {
        if (!element.estancia_min) {
          proceder = false
        } else if (element.estancia_min < 1) {
          proceder = false
        }
      })

      if (!proceder) {
        this.$toast.error(this.$t('msg.verifyEstanciaMin'))
      }

      if (proceder) {
        this.loading = true
        this.axios
          .post('contrate_hotels/season', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // console.log(response)
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              sessionStorage.removeItem('hotels-show')
              sessionStorage.removeItem('hotels-id')
              sessionStorage.removeItem('hotels-contrate-temporada_id')
              this.$router.push({ name: 'hotels-contrate-update' })
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loading = false))

        /* if (
        this.model.proveedor_id
        && this.model.identificador
        && this.idsHotelsAsociados.length > 0
      ) {
        let proceder = true

        if (this.observationsHotel.length > 0) {
          this.observationsHotel.forEach(element => {
            if (!element.description) {
              proceder = false
            }
          })
        }

        if (proceder) {
          this.loading = true
          if (sessionStorage.getItem('hotels-contrate-id') === null) {
            this.axios
              .post('contrate_hotels', json, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
                },
              })
              .then(response => {
                // console.log(response)
                if (response.data.success === false) {
                  if (response.data.data.status === 401) {
                    this.$toast.error(this.$t('msg.noAutorice'))
                  } else if (response.data.data.status === 402) {
                    this.$toast.error(this.$t('msg.existFlight', { numero: this.model.numero_de_vuelo }))
                  }
                } else {
                  sessionStorage.removeItem('hotels-contrate-id')
                  this.$router.push({ name: 'hotels-contrate-list' })
                }
              })
              .catch(error => console.log(error))
              // eslint-disable-next-line no-return-assign
              .finally(() => (this.loading = false))
          }
        } else {
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      } */
      }

      // console.log(json)
    },
    deleteContact(id) {
      const ind = this.contacts_proveedor.indexOf(id)

      if (ind > -1) {
        this.contacts_proveedor.splice(ind, 1)
      }
    },
    addNewContact() {
      if (this.contactAdd) {
        this.contacts_proveedor.push(this.contactAdd)
        this.contactAdd = null
      }
    },
    showItemContacts() {
      this.showRowContacts = !this.showRowContacts
    },
    showItemConditions() {
      this.showRowConditions = !this.showRowConditions
    },
    showItemSeasons() {
      this.showRowSeasons = !this.showRowSeasons
    },
    showItemCupos() {
      this.showRowCupos = !this.showRowCupos
    },
    showItemReleaseEstancia() {
      this.showRowReleaseEstancia = !this.showRowReleaseEstancia
    },
    showItemPricesEstancia() {
      this.showRowPricesEstancia = !this.showRowPricesEstancia
    },
    showItemSuplementos() {
      this.showRowSuplementos = !this.showRowSuplementos
    },
    showItemReducciones() {
      this.showRowReducciones = !this.showRowReducciones
    },
    showItemAcomodation() {
      this.showRowAcomodation = !this.showRowAcomodation
    },
    showItemRangoAge() {
      this.showRowRangoAge = !this.showRowRangoAge
    },
    showItemOfertas() {
      this.showRowOfertas = !this.showRowOfertas
    },
    showItemOfertasSpecial() {
      this.showRowOfertasSpecial = !this.showRowOfertasSpecial
    },
  },
}
</script>

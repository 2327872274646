<template>
  <fragment>
    <v-col
      cols="12"
      md="3"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-select
            v-model="reduccion.reduccion_id"
            :disabled="$store.state.app.onlyShow"
            :items="reduccionsFree"
            :label="$t('lbl.reduccion')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            single-line
            @change="setReduccion"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.reduccion') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              {{ item.name }}
              <!--<fragment v-if="item.description">
                ({{ item.description }})
              </fragment> -->
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.description">
                  {{ item.description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
          <span
            v-if="reduccion.reduccion_id"
            style="font-size: 12px;"
            class="text-caption"
          >
            {{ reduccionsFree.filter(e => e.id === reduccion.reduccion_id)[0].description }}
          </span>
          <!--<v-autocomplete
            v-model="room.room_id"
            :items="itemsRooms"
            :search-input.sync="searchRooms"
            hide-details
            hide-selected
            :label="$t('lbl.room')"
            outlined
            dense
            item-text="name"
            item-value="id"
            single-line
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.room') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>-->
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="!$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 mx-2"
            fab
            dark
            x-small
            color="error"
            @click="deleteReduccionSeasonContrateHotel(pos)"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-row>
        <v-col
          v-for="(price, indC) in reduccion.prices"
          :key="indC"
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="price.price"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.price')"
            class="my-input"
            outlined
            dense
            hide-details
            type="number"
            :prefix="!reduccion.in_pc ? '$' : ''"
            :suffix="reduccion.in_pc ? '%' : ''"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-switch
            v-model="reduccion.in_pc"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.porciento')"
            hide-details
            class="mt-0"
          ></v-switch>

          <v-switch
            v-model="reduccion.required"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.obligatorio')"
            hide-details
            class="mt-0"
          ></v-switch>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <v-select
            v-model="reduccion.rooms_id"
            :disabled="$store.state.app.onlyShow"
            :items="roomsFree"
            :label="$t('lbl.rooms')"
            outlined
            class="ml-5 mt-2"
            dense
            multiple
            hide-details
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.rooms') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    reduccion: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allReduccion: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    edad: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingReduccions: true,
      isDialogReduccion: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),

      // allReduccion: [],
      // allRooms: [],
      itemsRooms: [],

      itemsReduccion: [],
      searchReduccion: null,
    }
  },
  computed: {
    ...mapState({
      reduccionsSeasonContrateHotel: state => state.app.reduccionsSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      onlyAdult: state => state.app.onlyAdult,
    }),
    reduccionsFree() {
      const result = []
      this.allReduccion.forEach(element => {
        /* let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.reduccionsSeasonContrateHotel.length; index++) {
          if (this.reduccionsSeasonContrateHotel[index].reduccion_id === element.id && index !== this.pos) {
            esta = true
          }
        }
        if (!esta) {
          result.push(element)
        } */
        result.push(element)
      })

      if (this.onlyAdult) {
        const restructurado = []
        result.forEach(element => {
          if (
            element.slug !== 'infantes'
            && element.slug !== '1er-infante'
            && element.slug !== '2do-infante'
            && element.slug !== '1er-adolescente'
            && element.slug !== '2do-adolescente'
            && element.slug !== '1-adulto-con-1-nino-se-cobra-como-2-adultos-en-dbl'
            && element.slug !== 'ninos'
            && element.slug !== '1er-nino'
            && element.slug !== '1er-nino-con-1-adulto'
            && element.slug !== '1er-nino-con-2-adultos'
            && element.slug !== '2do-nino'
            && element.slug !== '2do-nino-con-1-adulto'
            && element.slug !== '2do-nino-con-2-adultos'
            && element.slug !== '2-ninos-solos-en-una-hab-pagan-precio-pax-en-sgl'
            && element.slug !== '2-ninos-solos-en-una-hab-pagan-precio-pax-en-dbl'
            && element.slug !== 'ninos-solos-en-la-habitacion'
            && element.slug !== 'ninos-solos-en-una-habitacion-el-1er-nino'
            && element.slug !== 'ninos-solos-en-una-habitacion-el-2do-nino'
            && element.slug !== '1-adulto-infantes-en-una-habitacion-los-infantes'
            && element.slug !== '1-adulto-infantes-en-una-habitacion-1er-infante'
            && element.slug !== '1-adulto-infantes-en-una-habitacion-2do-infante'
            && element.slug !== '1-adulto-ninos-en-una-habitacion-los-ninos'
            && element.slug !== '1-adulto-ninos-en-una-habitacion-el-1er-nino'
            && element.slug !== '1-adulto-ninos-en-una-habitacion-el-2do-nino'
            && element.slug !== '2-adultos-infantes-en-una-habitacion-los-infantes'
            && element.slug !== '2-adultos-infantes-en-una-habitacion-1er-infante'
            && element.slug !== '2-adultos-infantes-en-una-habitacion-2do-infante'
            && element.slug !== '2-adultos-ninos-en-una-habitacion-los-ninos'
            && element.slug !== '2-adultos-ninos-en-una-habitacion-el-1er-nino'
            && element.slug !== '2-adultos-ninos-en-una-habitacion-el-2do-nino'
            && element.slug !== 'suplemento-de-desayuno-para-ninos-cp'
            && element.slug !== 'desayuno-para-ninos-cp'
            && element.slug !== 'suplemento-de-cena-para-ninos-map'
            && element.slug !== 'cena-para-ninos-map'
            && element.slug !== 'suplemento-de-alimentacion-full-board-para-ninos-ap'
            && element.slug !== 'alimentacion-full-board-para-ninos-ap'
          ) {
            restructurado.push(element)
          }

          if ((element.slug === '1er-adolescente' || element.slug === '2do-adolescente') && this.edad <= 11) {
            restructurado.push(element)
          }
        })

        return restructurado
      }

      return result
    },
    roomsFree() {
      const result = []
      this.allRooms.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.roomsSeasonContrateHotel.length; index++) {
          if (this.roomsSeasonContrateHotel[index].room_id === element.id) {
            esta = true
          }
        }
        if (esta) {
          result.push(element)
        }
      })

      return result
    },
  },
  watch: {
    searchReduccion(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterReduccion(val.toLowerCase())
      }
    },
  },
  mounted() {
    // this.getReduccions()
    // this.getRooms()
    if (this.roomsFree.length === 1) {
      this.reduccion.rooms_id = [this.roomsFree[0].id]
    }
  },
  methods: {
    ...mapMutations(['deleteReduccionSeasonContrateHotel']),
    filterReduccion() {
      const suplem = this.allReduccion.filter(e => e.name.toLowerCase())
      suplem.forEach(element => {
        let esta = false
        this.reduccionsSeasonContrateHotel.forEach(rrr => {
          if (rrr.reduccion_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsReduccion.push(element)
        }
      })
    },
    setReduccion() {
      this.reduccion.name = this.allReduccion.filter(e => e.id === this.reduccion.reduccion_id)[0].name
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}

::v-deep .v-list-item {
  margin-bottom: 10px;
}
</style>

<template>
  <fragment>
    <v-col
      cols="12"
      md="3"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-select
            v-model="suplement.suplement_id"
            :disabled="$store.state.app.onlyShow"
            :items="suplementsFree"
            :label="$t('lbl.suplemento')"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            @change="setSuplement"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.suplemento') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
          <!--<v-autocomplete
            v-model="room.room_id"
            :items="itemsRooms"
            :search-input.sync="searchRooms"
            hide-details
            hide-selected
            :label="$t('lbl.room')"
            outlined
            dense
            item-text="name"
            item-value="id"
            single-line
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.room') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>-->
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="!$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 mx-2"
            fab
            dark
            x-small
            color="error"
            @click="deleteSuplementSeasonContrateHotel(pos)"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-row>
        <v-col
          v-for="(price, indC) in suplement.prices"
          :key="indC"
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="price.price"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.price')"
            class="my-input"
            outlined
            dense
            hide-details
            type="number"
            :prefix="!suplement.in_pc ? '$' : ''"
            :suffix="suplement.in_pc ? '%' : ''"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-switch
            v-model="suplement.in_pc"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.porciento')"
            hide-details
            class="mt-0"
          ></v-switch>

          <v-switch
            v-model="suplement.required"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.obligatorio')"
            hide-details
            class="mt-0"
          ></v-switch>
        </v-col>
        <v-col
          cols="12"
          md="10"
        >
          <v-select
            v-model="suplement.rooms_id"
            :disabled="$store.state.app.onlyShow"
            :items="roomsFree"
            :label="$t('lbl.rooms')"
            outlined
            class="ml-5 mt-2"
            dense
            multiple
            hide-details
            item-text="name"
            item-value="id"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.rooms') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    suplement: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    allSuplement: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    onlyAdult: {
      type: Boolean,
    },
    allInclude: {
      type: Boolean,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),

      // allSuplement: [],
      // allRooms: [],
      itemsRooms: [],

      itemsSuplement: [],
      searchSuplement: null,
    }
  },
  computed: {
    ...mapState({
      suplementsSeasonContrateHotel: state => state.app.suplementsSeasonContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
    }),
    suplementsFree() {
      let result = []
      if (this.allInclude) {
        this.allSuplement.forEach(element => {
          if (
            element.slug !== 'suplemento-de-alimentacion-full-board-para-adultos-ap'
            && element.slug !== 'suplemento-de-alimentacion-full-board-ap'
            && element.slug !== 'suplemento-de-alimentacion-full-board-para-ninos-ap'
            && element.slug !== 'suplemento-de-cena-map'
            && element.slug !== 'suplemento-de-cena-para-adultos-map'
            && element.slug !== 'suplemento-de-cena-para-ninos-map'
            && element.slug !== 'suplemento-de-desayuno-para-adultos-cp'
            && element.slug !== 'suplemento-de-desayuno-para-ninos-cp'
            && element.slug !== 'suplemento-de-desayuno'
            && element.slug !== 'suplemento-de-desayuno-cp'
          ) {
            result.push(element)
          }
        })
      } else if (this.onlyAdult) {
        this.allSuplement.forEach(element => {
          if (
            element.slug !== 'suplemento-de-alimentacion-full-board-para-adultos-ap'
            && element.slug !== 'suplemento-de-alimentacion-full-board-para-ninos-ap'
            && element.slug !== 'suplemento-de-cena-para-adultos-map'
            && element.slug !== 'suplemento-de-cena-para-ninos-map'
            && element.slug !== 'suplemento-de-desayuno-para-adultos-cp'
            && element.slug !== 'suplemento-de-desayuno-para-ninos-cp'
          ) {
            result.push(element)
          }
        })
      } else {
        result = this.allSuplement
      }

      /* const result = []
      this.allSuplement.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.suplementsSeasonContrateHotel.length; index++) {
          if (this.suplementsSeasonContrateHotel[index].suplement_id === element.id && index !== this.pos) {
            esta = true
          }
        }
        if (!esta) {
          result.push(element)
        }
      }) */

      return result
    },
    roomsFree() {
      const result = []
      this.allRooms.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.roomsSeasonContrateHotel.length; index++) {
          if (this.roomsSeasonContrateHotel[index].room_id === element.id) {
            esta = true
          }
        }
        if (esta) {
          result.push(element)
        }
      })

      return result
    },
  },
  watch: {
    searchSuplement(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterSuplement(val.toLowerCase())
      }
    },
  },
  created() {
    // this.getSuplements()
    // this.getRooms()
    if (this.roomsFree.length === 1) {
      this.suplement.rooms_id = [this.roomsFree[0].id]
    }
  },
  methods: {
    ...mapMutations(['deleteSuplementSeasonContrateHotel']),
    filterSuplement() {
      const suplem = this.allSuplement.filter(e => e.name.toLowerCase())
      suplem.forEach(element => {
        let esta = false
        this.suplementsSeasonContrateHotel.forEach(rrr => {
          if (rrr.suplement_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsSuplement.push(element)
        }
      })
    },
    setSuplement() {
      this.suplement.name = this.allSuplement.filter(e => e.id === this.suplement.suplement_id)[0].name
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>

<template>
  <fragment>
    <v-col
      cols="12"
      md="3"
    >
      <v-row>
        <v-col
          v-if="pos === 0"
          cols="12"
          md="12"
        >
          <strong style="opacity:0;">.</strong>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="room.name"
            class="my-input"
            hide-details
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="1"
    ></v-col>
    <v-col
      cols="12"
      md="8"
    >
      <SeasonAcomodationRoomMax
        v-for="(ocupation, indOcu) in room.max_ocupations"
        :key="indOcu"
        :ocupation="ocupation"
        :pos-ocupation="indOcu"
        :pos="pos"
        :room="room"
      />
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import SeasonAcomodationRoomMax from './SeasonAcomodationRoomMax.vue'

export default {
  components: {
    SeasonAcomodationRoomMax,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      allRooms: [],
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
    }
  },
  computed: {
    ...mapState({
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      onlyAdult: state => state.app.onlyAdult,
      allInclude: state => state.app.allInclude,
    }),
  },
  methods: {
    ...mapMutations(['deleteRoomSeasonContrateHotel']),
  },
}
</script>

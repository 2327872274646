<template>
  <fragment>
    <v-col
      cols="12"
      md="3"
    >
      <v-row no-gutters>
        <v-col
          cols="12"
          md="8"
        >
          <v-tooltip
            v-model="show"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-select
                v-model="room.room_id"
                :disabled="$store.state.app.onlyShow"
                :items="roomsFree"
                :label="$t('lbl.room')"
                class="my-input mr-2"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                v-bind="attrs"
                @change="setRoom"
                @mouseover.native="focus()"
                v-on="on"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.room') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </template>
            <span>{{ nameRoom }}</span>
          </v-tooltip>
          <!--<v-autocomplete
            v-model="room.room_id"
            :items="itemsRooms"
            :search-input.sync="searchRooms"
            hide-details
            hide-selected
            :label="$t('lbl.room')"
            outlined
            dense
            item-text="name"
            item-value="id"
            single-line
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.room') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>-->
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-model="room.plan_id"
            :disabled="$store.state.app.onlyShow"
            :items="planes"
            :label="$t('lbl.plan')"
            class="my-input"
            outlined
            dense
            hide-details
            item-text="siglas"
            item-value="id"
            :append-icon="room.plan_id && pos === 0 ? 'mdi-arrow-down' : ''"
            @click:append="iconSet()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.regimen') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="1"
    >
      <v-tooltip
        v-if="!$store.state.app.onlyShow"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 mx-2"
            fab
            dark
            x-small
            color="error"
            v-bind="attrs"
            v-on="on"
            @click="deleteRoomSeasonContrateHotel(pos)"
          >
            <v-icon small>
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <v-row>
        <v-col
          v-for="(cupo, indC) in room.cupos"
          :key="indC"
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="cupo.cupo"
            :disabled="$store.state.app.onlyShow"
            :label="$t('lbl.cupo')"
            class="my-input"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      allRooms: [],
      roomsHotel: [],
      planes: [],

      itemsRooms: [],
      searchRooms: null,
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
    }),
    roomsFree() {
      const result = []
      this.roomsHotel.forEach(element => {
        let esta = false
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.roomsSeasonContrateHotel.length; index++) {
          if (this.roomsSeasonContrateHotel[index].room_id === element.id && index !== this.pos) {
            esta = true
          }
        }
        if (!esta) {
          result.push(element)
        }
      })

      return result
    },
  },
  watch: {
    searchRooms(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterRooms(val.toLowerCase())
      }
    },
  },
  created() {
    this.getRooms()
    this.getPlans()
  },
  methods: {
    ...mapMutations(['deleteRoomSeasonContrateHotel', 'replicaPlanRoomSeasonContrateHotel']),
    focus() {
      if (this.room.room_id) {
        this.nameRoom = this.allRooms.filter(e => e.id === this.room.room_id)[0].name
        this.show = true
        setTimeout(() => {
          this.show = false
        }, 1000)
      }
    },
    getPlans() {
      this.axios
        .get(`nom_regimen_alimenticio?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.planes = res.data.data
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allRooms = res.data.data
        })
        .finally(() => this.getHotel())
    },
    getHotel() {
      if (sessionStorage.getItem('hotels-id') !== null) {
        const id = sessionStorage.getItem('hotels-id')
        this.axios
          .get(`hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.allRooms.forEach(element => {
              if (res.data.data.data.rooms_id.includes(element.id)) {
                this.roomsHotel.push(element)
              }
            })

            if (this.roomsHotel.length === 1) {
              this.room.room_id = this.roomsHotel[0].id
            }
          })
      }
    },
    filterRooms() {
      const rooms = this.roomsHotel.filter(e => e.name.toLowerCase())
      rooms.forEach(element => {
        let esta = false
        this.roomsSeasonContrateHotel.forEach(rrr => {
          if (rrr.room_id === element.id) {
            esta = true
          }
        })
        if (!esta) {
          this.itemsRooms.push(element)
        }
      })
    },
    setRoom() {
      this.room.name = this.roomsHotel.filter(e => e.id === this.room.room_id)[0].name
    },
    iconSet() {
      if (this.room.plan_id) {
        this.replicaPlanRoomSeasonContrateHotel({ pos: this.pos, plan_id: this.room.plan_id })
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input select {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 10px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
}
</style>

<template>
  <fragment>
    <v-col
      cols="12"
      md="2"
    >
      <v-text-field
        v-model="season.release"
        :disabled="$store.state.app.onlyShow"
        :label="$t('lbl.day')"
        class="my-input mb-4"
        outlined
        dense
        type="number"
        hide-details
      ></v-text-field>
      <v-text-field
        v-model="season.estancia_min"
        :disabled="$store.state.app.onlyShow"
        :label="$t('lbl.day')"
        class="my-input"
        outlined
        dense
        type="number"
        hide-details
      ></v-text-field>
    </v-col>
  </fragment>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    season: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      loadingSeason: true,
      pagination: {
        current: 1,
        total: 0,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      itemsPerPage: 10,
      hotelsNomAll: [],
      permisos: sessionStorage.getItem('permisos_auth'),
      showRowSeasons: false,
    }
  },

  methods: {
    ...mapMutations(['deleteSeasonContrateHotel']),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>

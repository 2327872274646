<template>
  <fragment>
    <v-row>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-if="posOcupation === 0"
          :label="$t('lbl.minPax')"
          class="my-input mb-4"
          hide-details
          disabled
        ></v-text-field>
        <v-text-field
          v-model="ocupation.min_pax"
          :disabled="$store.state.app.onlyShow"
          label="Pax"
          class="my-input"
          outlined
          dense
          hide-details
          type="number"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-if="posOcupation === 0"
          :label="$t('lbl.maxAdl')"
          class="my-input mb-4"
          hide-details
          disabled
        ></v-text-field>
        <v-text-field
          v-model="ocupation.max_adult"
          :disabled="$store.state.app.onlyShow"
          :label="$t('lbl.adultos')"
          class="my-input"
          outlined
          dense
          hide-details
          type="number"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-if="posOcupation === 0"
          :label="$t('lbl.maxMnr')"
          class="my-input mb-4"
          hide-details
          disabled
        ></v-text-field>
        <v-text-field
          v-model="ocupation.max_child"
          :disabled="$store.state.app.onlyShow || onlyAdult"
          :label="$t('lbl.childs')"
          class="my-input"
          outlined
          dense
          hide-details
          type="number"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="2"
      >
        <v-text-field
          v-if="posOcupation === 0"
          :label="$t('lbl.maxPax')"
          class="my-input mb-4"
          hide-details
          disabled
        ></v-text-field>
        <v-text-field
          v-model="ocupation.max_pax"
          :disabled="$store.state.app.onlyShow"
          label="Pax"
          class="my-input"
          outlined
          dense
          hide-details
          type="number"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="1"
        class="mr-2"
      >
        <v-text-field
          v-if="posOcupation === 0"
          :label="$t('btn.delete')"
          class="my-input mb-4"
          style="opacity:0;"
          hide-details
          disabled
        ></v-text-field>
        <v-tooltip
          v-if="room.max_ocupations.length > 1"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              v-bind="attrs"
              fab
              dark
              x-small
              color="error"
              v-on="on"
              @click="deleteOcupationRoomSeasonContrateHotel({ posRoom: pos, posOcupation: posOcupation })"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-col>
      <v-col
        cols="12"
        md="1"
        class="ml-2"
      >
        <v-text-field
          v-if="posOcupation === 0"
          :label="$t('btn.insert')"
          class="my-input mb-4"
          style="opacity:0;"
          hide-details
          disabled
        ></v-text-field>
        <v-tooltip
          v-if="posOcupation === 0 && !$store.state.app.onlyShow"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addOcupationRoomSeasonContrateHotel(pos)"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    ocupation: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    posOcupation: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      allRooms: [],
      roomsHotel: [],

      itemsRooms: [],
      searchRooms: null,
    }
  },
  computed: {
    ...mapState({
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      onlyAdult: state => state.app.onlyAdult,
      allInclude: state => state.app.allInclude,
    }),
  },
  created() {
    if (this.onlyAdult) {
      this.ocupation.max_child = 0
    }
  },
  methods: {
    ...mapMutations(['addOcupationRoomSeasonContrateHotel', 'deleteOcupationRoomSeasonContrateHotel']),
  },
}
</script>
